import type { ReactNode } from 'react';
import classNames from 'classnames';
import { VRM_LOOKUP_MODAL_OPENED } from '@/util/ga4Analytics';
import type { QueueModalProps } from '@/components/atoms/ModalTrigger';
import { ModalTrigger } from '@/components/atoms/ModalTrigger';
import { Img } from '@/components/atoms/Img';

import type { QueuedModalDesign } from '@/types/components';
import styles from './index.module.scss';

export type VrmLookupDumbModalProps = QueueModalProps & {
  readonly heading: ReactNode;
  readonly description?: string;
  /**
   * Track analytics event `modal:VRM_LOOKUP_MODAL_OPENED` source.
   */
  readonly triggeredFrom?: string;
  readonly garageId?: string;
  readonly isMembershipDomain?: boolean;
  readonly modalDesign?: QueuedModalDesign;
};

export function VrmLookupDumbModal({
  heading,
  description,
  triggeredFrom,
  garageId,
  isMembershipDomain,
  modalDesign,
  children,
  ...buttonProps
}: VrmLookupDumbModalProps): JSX.Element {
  const showModalEvent = triggeredFrom
    ? ([VRM_LOOKUP_MODAL_OPENED, triggeredFrom] as const)
    : VRM_LOOKUP_MODAL_OPENED;
  return (
    <ModalTrigger trackShowModal={showModalEvent} modalDesign={modalDesign} {...buttonProps}>
      <div className={classNames(styles.modalContent, modalDesign && styles[modalDesign])}>
        {isMembershipDomain && garageId && (
          <Img
            src={`https://res.cloudinary.com/fxtr/image/upload/v1719850745/garages/garage-images/${garageId}/garageLogo.png`}
            alt="fixter logo"
            className={styles.GarageLogo}
            width={172}
            height={36}
            quality={75}
            provider="cloudinary"
          />
        )}
        <h1 className={styles.modalHeading}>{heading}</h1>
        {description && <p className={styles.modalDescription}>{description}</p>}
        {children}
      </div>
    </ModalTrigger>
  );
}
